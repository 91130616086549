<template>
  <div class="finished-onboarding">
    <div class="message-container">
      <h2>Processo finalizado!</h2>
      <p>O processo de captura e envio da documentação foi enviado e está sendo analisado.</p>
      <p>Agora aguarde as instruções da página que acesso o QRCode.</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
  }
};
</script>

<style scoped>
.finished-onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.message-container {
  margin: 25px;
  text-align: center;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-container h2 {
  margin-bottom: 1rem;
  color: #4caf50;
}

.message-container p {
  margin-bottom: 1rem;
  color: #333;
}

.continue-button {
  margin-top: 1rem;
}
</style>
