<template>
  <div>
    <DocumentCapture
      v-if="shouldShowDocumentCapture"
      :key="captureKey"
      :isFront="isFront"
      :photoAccepted="handlePhotoAccepted"
    />
    <LifeProofCapture
      v-if="shouldShowLifeProofCapture"
      :key="key"
      :type="type"
      :videoRecorded="videoRecorded"
      :retryUpload="retryUpload"
      @selfieCaptured="selfieCaptured"
      @finish="completeOnboarding"
    />
    <DocumentCaptureFinished v-if="showDocumentCaptureFinished" />
  </div>
</template>

<script>
import DocumentCapture from './DocumentCapture.vue';
import LifeProofCapture from './LifeProofCapture.vue';
import DocumentCaptureFinished from './DocumentCaptureFinished.vue';
import DocumentPersonService from '@/services/api/DocumentPersonService';

export default {
  components: {
    DocumentCapture,
    LifeProofCapture,
    DocumentCaptureFinished
  },
  props: {
    type: {
      type: String,
      default: 'both',
    },
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      key: 0,
      document: {
        photo_front: '',
        photo_back: '',
        photo_face: '',
        video: '',
      },
      service: {
        service: null
      },
      isFront: true,
      captureKey: 0,
      documentCompleted: false,
      liveProofCompleted: false,
      videoUploadError: false
    }
  },
  computed: {
    shouldShowDocumentCapture() {
      return (this.type === 'both' || this.type === 'doc-photo') && !this.documentCompleted;
    },
    shouldShowLifeProofCapture() {
      return this.documentCompleted && !this.liveProofCompleted;
    },
    showDocumentCaptureFinished() {
      return this.liveProofCompleted || this.documentCompleted;
    }
  },
  methods: {
    async sendDocument(callbackSuccess, callbackError) {
      this.$vs.loading();
      try {
        await this.service.uploadDocument(this.type, this.token, this.document);
        this.$vs.loading.close();

        if (this.type === 'both' || this.type === 'doc-photo') {
          this.documentCompleted = true;
          this.liveProofCompleted = true;
        }
        if (this.type === 'both' || this.type === 'liveness-video') {
          this.liveProofCompleted = true;
        }

        if (callbackSuccess) callbackSuccess();
      } catch (error) {
        this.$vs.loading.close();
        this.videoUploadError = true;
        if (callbackError) callbackError();
      }
    },
    clearData() {
      this.document.photo_front = null;
      this.document.photo_back = null;
    },
    handlePhotoAccepted(image, callbackSuccess, callbackError) {
      if (this.isFront) {
        this.document.photo_front = image;
        this.isFront = false;
      } else {
        this.document.photo_back = image;
      }

      if (this.document.photo_front && this.document.photo_back) {
        this.documentCompleted = true;
      }

      this.captureKey += 1;
    },
    handleLifeProofAccepted(image) {
      console.log('Life proof accepted:', image);
    },
    videoRecorded(video, callbackSuccess, callbackError) {
      this.document.video = video;
      if (this.type === 'both' || this.type === 'liveness-video') {
        this.sendDocument(callbackSuccess, callbackError);
      }
    },
    selfieCaptured(photo_face, callbackSuccess, callbackError) {
      this.document.photo_face = photo_face;

      if (this.type === 'doc-photo') {
        this.sendDocument(callbackSuccess, callbackError);
      }
    },
    async retryUpload(callbackSuccess, callbackError) {
      if (this.videoUploadError) {
        this.videoUploadError = false;
        await this.sendDocument(callbackSuccess, callbackError);
      }
    },
    completeOnboarding() {
      this.$router.push('/document_capture_finished');
    }
  },
  beforeMount() {
    this.service = DocumentPersonService.build(this.$vs);
  }
}
</script>
