var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "cameraContainer", staticClass: "camera-view" },
    [
      _c(
        "vs-popup",
        {
          attrs: { active: _vm.showHelpModal, title: "Instruções" },
          on: {
            "update:active": function ($event) {
              _vm.showHelpModal = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("h2", { staticClass: "text-lg font-semibold mb-2" }, [
                _vm._v(_vm._s(_vm.titleInstruction)),
              ]),
              _c("img", {
                staticClass: "mx-auto",
                staticStyle: { "max-width": "90px" },
                attrs: {
                  src: require("@/assets/images/document/selfie_instruction.png"),
                  alt: "document",
                },
              }),
              _c("p", { staticClass: "text-gray-600 mb-4" }, [
                _vm._v("Coloque seu rosto dentro da área oval."),
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "px-4 py-2",
                  attrs: { color: "success" },
                  on: {
                    click: function ($event) {
                      _vm.showHelpModal = false
                    },
                  },
                },
                [_vm._v(" OK, entendi ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.showLandscapeWarning,
            title: "Atenção",
            "button-close-hidden": true,
          },
          on: {
            "update:active": function ($event) {
              _vm.showLandscapeWarning = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("p", { staticClass: "text-gray-600 mb-4" }, [
              _vm._v(
                "A captura deve ser feita em modo retrato. Por favor, vire o dispositivo."
              ),
            ]),
          ]),
        ]
      ),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.capturedVideo && !_vm.showLandscapeWarning,
            expression: "!capturedVideo && !showLandscapeWarning",
          },
        ],
        ref: "video",
        staticClass: "video",
        attrs: { autoplay: "", playsinline: "" },
      }),
      _c("canvas", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.capturedVideo && !_vm.showLandscapeWarning,
            expression: "!capturedVideo && !showLandscapeWarning",
          },
        ],
        ref: "overlay",
        staticClass: "overlay",
      }),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.capturedVideo && !_vm.showLandscapeWarning,
              expression: "!capturedVideo && !showLandscapeWarning",
            },
          ],
          staticClass: "instructions",
        },
        [_vm._v(_vm._s(_vm.instructionText))]
      ),
      _c(
        "vs-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.capturedVideo && !_vm.showLandscapeWarning,
              expression: "!capturedVideo && !showLandscapeWarning",
            },
          ],
          staticClass: "capture-button",
          attrs: {
            disabled: !_vm.canCapture,
            color: "primary",
            icon: "camera",
          },
          on: { click: _vm.nextInstruction },
        },
        [_vm._v(_vm._s(_vm.buttonText))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }