var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "finished-onboarding" }, [
      _c("div", { staticClass: "message-container" }, [
        _c("h2", [_vm._v("Processo finalizado!")]),
        _c("p", [
          _vm._v(
            "O processo de captura e envio da documentação foi enviado e está sendo analisado."
          ),
        ]),
        _c("p", [
          _vm._v("Agora aguarde as instruções da página que acesso o QRCode."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }