var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.shouldShowDocumentCapture
        ? _c("DocumentCapture", {
            key: _vm.captureKey,
            attrs: {
              isFront: _vm.isFront,
              photoAccepted: _vm.handlePhotoAccepted,
            },
          })
        : _vm._e(),
      _vm.shouldShowLifeProofCapture
        ? _c("LifeProofCapture", {
            key: _vm.key,
            attrs: {
              type: _vm.type,
              videoRecorded: _vm.videoRecorded,
              retryUpload: _vm.retryUpload,
            },
            on: {
              selfieCaptured: _vm.selfieCaptured,
              finish: _vm.completeOnboarding,
            },
          })
        : _vm._e(),
      _vm.showDocumentCaptureFinished
        ? _c("DocumentCaptureFinished")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }