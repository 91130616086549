<template>
  <div class="camera-view" ref="cameraContainer">
    <vs-popup :active.sync="showHelpModal" title="Instruções">
      <div class="text-center">
        <img :src="getImageSrc" alt="document" class="mx-auto" style="max-width: 90px;"/>
        <h2 class="text-lg font-semibold mb-2">{{ isFront ? 'Documento Frente' : 'Documento Verso' }}</h2>
        <p class="text-gray-600 mb-4">Posicione o documento em uma mesa, centralize-o na marcação e aguarde a captura automática.</p>
        <vs-button @click="showHelpModal = false" class="px-4 py-2" color="success">
          OK, entendi
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup :active.sync="showPortraitWarning" title="Atenção" :button-close-hidden="true">
      <div class="text-center">
        <p class="text-gray-600 mb-4">A captura de documento deve ser feita em modo paisagem. Por favor, vire o dispositivo.</p>
      </div>
    </vs-popup>

    <div v-if="capturedImage && !showPortraitWarning" class="preview grid grid-cols-3 gap-4">
      <div class="col-span-2">
        <img :src="capturedImage" alt="captured document" class="mx-auto" style="max-width: 100%;"/>
      </div>
      <div class="flex flex-col justify-center items-center">
        <p class="text-gray-600 mb-4">A foto está legível?</p>
        <vs-button @click="acceptPhoto" class="px-4 py-2" color="success">
          Sim, prosseguir
        </vs-button>
        <vs-button @click="retryPhoto" class="px-4 py-2 mt-2" color="danger">
          Não, tentar novamente
        </vs-button>
      </div>
    </div>

    <video v-show="!capturedImage && !showPortraitWarning" ref="video" autoplay playsinline class="video"></video>
    <canvas v-show="!capturedImage && !showPortraitWarning" ref="overlay" class="overlay"></canvas>
    <vs-button v-show="!capturedImage && !showPortraitWarning" @click="capturePhoto" class="capture-button" color="primary" icon="camera"></vs-button>
  </div>
</template>

<script>
export default {
  props: {
    isFront: {
      type: Boolean,
      default: true
    },
    photoAccepted: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showHelpModal: true,
      showPortraitWarning: false,
      capturedImage: null,
      rectWidth: null,
      rectHeight: null,
      rectX: null,
      rectY: null,
      videoStream: null
    };
  },
  computed: {
    getImageSrc() {
      return this.isFront ? require('@/assets/images/document/rg_frente.png') : require('@/assets/images/document/rg_verso.png');
    }
  },
  mounted() {
    this.startCamera();
    window.addEventListener('resize', this.handleOrientationChange);
    this.handleOrientationChange(); // Call once on mount to set initial orientation
  },
  methods: {
    async startCamera() {
      try {
        const constraints = {
          video: {
            facingMode: 'environment', // Use the rear camera on mobile devices
            width: { ideal: 1280 },
            height: { ideal: 720 }
          }
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.$refs.video.srcObject = stream;
        this.videoStream = stream;
        this.drawOverlay();
      } catch (err) {
        console.error('Error accessing the camera: ', err);
        alert('Erro ao acessar a câmera. Por favor, verifique as permissões.');
      }
    },
    handleOrientationChange() {
      const isPortrait = window.innerHeight > window.innerWidth;
      const cameraView = this.$refs.cameraContainer;
      const videoView = this.$refs.video;
      const overlayCanvas = this.$refs.overlay;
      this.isPortrait = isPortrait;

      this.rectWidth = window.innerWidth * 0.6;
      this.rectHeight = window.innerHeight * 0.7;
      this.rectX = (window.innerWidth - this.rectWidth) / 4;
      this.rectY = (window.innerHeight - this.rectHeight) / 2;

      if (isPortrait) {
        this.showPortraitWarning = true;
      } else {
        this.showPortraitWarning = false;

        if (cameraView) {
          cameraView.style.width = '100%';
          cameraView.style.height = '100%';
        }

        if (videoView) {
          videoView.style.transform = 'rotate(0deg)';
          videoView.style.height = 'auto';
        }

        if (overlayCanvas) {
          overlayCanvas.width = window.innerWidth;
          overlayCanvas.height = window.innerHeight;
        }
      }
    },
    drawOverlay() {
      const canvas = this.$refs.overlay;
      const context = canvas.getContext('2d');
      const drawFrame = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        this.drawDocumentFrame(context, canvas.width, canvas.height);
        requestAnimationFrame(drawFrame);
      };
      drawFrame();
    },
    drawDocumentFrame(context, width, height) {
      if (this.isPortrait) return;

      context.strokeStyle = 'green';
      context.lineWidth = 2;
      context.setLineDash([5, 5]);
      context.strokeRect(this.rectX, this.rectY, this.rectWidth, this.rectHeight);

      // Adicionar texto acima do retângulo
      context.font = '20px Arial';
      context.fillStyle = 'white';
      context.textAlign = 'center';
      context.fillText(this.isFront ? 'Frente do documento' : 'Verso do documento', width / 2.5, this.rectY - 10);
    },
    capturePhoto() {
      const video = this.$refs.video;
      const tempCanvas = document.createElement('canvas');
      const tempContext = tempCanvas.getContext('2d');

      // Configurações do canvas temporário
      tempCanvas.width = this.rectWidth;
      tempCanvas.height = this.rectHeight;

      // Calcule a proporção entre a dimensão real do vídeo e a dimensão desenhada na tela
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;
      const scaleX = videoWidth / window.innerWidth;
      const scaleY = videoHeight / window.innerHeight;

      // Calcule as coordenadas reais e dimensões do retângulo no vídeo
      const actualX = this.rectX * scaleX;
      const actualY = this.rectY * scaleY;
      const actualWidth = this.rectWidth * scaleX;
      const actualHeight = this.rectHeight * scaleY;

      // Desenhe a parte correspondente do vídeo no canvas temporário
      tempContext.drawImage(video, actualX, actualY, actualWidth, actualHeight, 0, 0, this.rectWidth, this.rectHeight);

      // Obtenha a URL da imagem capturada
      const capturedDataUrl = tempCanvas.toDataURL('image/png');
      this.capturedImage = capturedDataUrl;
    },
    acceptPhoto() {
      // Lógica para aceitar a foto capturada
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop());
      }
      this.photoAccepted(this.capturedImage, () => {}, () => {
        this.instructionText = 'Não foi possível enviar os documentos, tente novamente.'
        this.uploadErrorFlag = true;
      });

      this.capturedImage = null;
    },
    retryPhoto() {
      // Lógica para tentar capturar a foto novamente
      this.capturedImage = null;
      this.startCamera();
    }
  }
};
</script>

<style scoped>
.camera-view {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.capture-button {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensure the button is above the canvas */
  cursor: pointer; /* Indicate that it is clickable */
  padding: 32px 32px; /* Increase padding */
}

.capture-button > i {
  font-size: 30px;
}

.preview {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Create two columns with a 2:1 ratio */
  gap: 16px; /* Add space between columns */
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 0 auto;
}
</style>
