import Rest from '../Rest'

export default class DocumentPersonService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/document_persons'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  existsDocument(docType: String) {
    return this.get(`types/${docType}/exists`)
  }

  getQrCodeDataToUploadDocument(docType: String) {
    return this.get(`types/${docType}/qrcode`)
  }

  uploadDocument(docType: String, token: String, dataParam: any) {
    return this.post(`types/${docType}/${token}/upload`, {document: dataParam})
  }

}
